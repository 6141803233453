import React, { useEffect } from 'react';

const DownloadBett = () => {
  useEffect(() => {
    const link = document.createElement('a');
    link.href = '/static/CT brochure_print.pdf';
    link.download = 'Bett2023 Brochure.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
  }, []);


  return (
    <div className="text-center margin-auto">
    <p><img src={require("~images/products/ctxbett2023.png").default} alt="Bett 2023 Brochure"/></p>
    <h4>You are downloading Bett 2023 Brochure ...</h4>
  </div>
  );
};

export default DownloadBett;